@import 'style';

.blocks {
	> .block:last-child:not(.alert):not(.articles):not(.backgroundblock):not(
			.cases
		):not(.contact):not(.related):not(.testimonials) {
		padding-bottom: 75px;
	}
}

.block {
	&.mod-dark {
		background-color: $dark-grey;
		padding: 50 * $px;
		color: white;
	}

	&.mod-grey {
		background-color: $light-grey;
		padding: 50 * $px 0;
	}

	& + & {
		margin-top: 60px;
	}

	&.alert,
	&.backgroundblock,
	&.cases,
	&.centered,
	&.contact,
	&.downloads,
	&.hero,
	&.inpagenav,
	&.overview,
	&.testimonials,
	&.textblock.mod-dark,
	&.textblock.mod-grey {
		& + & {
			margin-top: 0;
		}
	}

	&.hero + &.inpagenave,
	&.relatedjobs.mod-contentview + &.centered.mod-background {
		margin-top: 0;
	}

	&.textblock.mod-grey + &.overview {
		padding-top: 0;
	}
}
