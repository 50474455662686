$color: rgb(63, 151, 189);
$colorRgb: 63, 151, 189;
$px: 1em/16;

:root {
	--aa-icon-color-rgb: 63, 151, 189 !important;
	--aa-input-border-color-rgb: 144, 144, 144 !important;
	--aa-primary-color-rgb: 63, 151, 189 !important;
}

.aa-Panel {
	z-index: 10;
}

.aa-SubmitButton {
	cursor: pointer;
}

.ais-Highlight-highlighted,
.ais-Snippet-highlighted {
	background-color: rgba($color, 0.1);
	color: $color;
	font-weight: bold;
}

.ais-Hits-item,
.ais-InfiniteHits-item {
	padding: 0 !important;
	list-style: none;
}

.ais-InfiniteHits {
	display: flex;
	flex-direction: column;
}

.ais-InfiniteHits-loadPrevious,
.ais-InfiniteHits-loadMore {
	color: $color;
	padding: 60 * $px 0;
	font-weight: bold;
	margin: 20px auto;
	text-decoration: underline solid transparent;
	transition: text-decoration 0.3s ease;
	&:hover {
		text-decoration: underline solid currentColor;
		cursor: pointer;
	}

	&.ais-InfiniteHits-loadMore--disabled {
		display: none;
	}
}
