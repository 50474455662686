@import 'style';

body {
	color: $text;
	background-color: $background;
	font-family: 'Agenda', 'Arial', 'Helvetica', sans-serif;
	font-weight: 300;
	font-size: 16px * (100vw / 360px);
	line-height: (28/16);

	@media only screen and (min-width: 360) {
		font-size: 16px * (100vw / 400px);
	}

	@include breakpoint('small') {
		font-size: 16px * (100vw / 475px);
	}

	@include breakpoint('tablet') {
		font-size: 16px * (100vw / 640px);
	}

	@include breakpoint('ipad-port') {
		font-size: 16px * (100vw / 900px);
	}

	@include breakpoint('ipad-land') {
		font-size: 16px;
	}

	@include breakpoint('extreme') {
		font-size: 16px * (100vw / 2240px);
	}
}

//RESET
* {
	margin: 0;
	padding: 0;

	&,
	&:before,
	&:after {
		box-sizing: border-box;
	}
}

a {
	color: inherit;
	cursor: pointer;
	text-decoration: none;

	img {
		border: none;
	}
}

button {
	cursor: pointer;
	color: inherit;
}

button,
input,
select,
textarea {
	font: inherit;
	border: none;
	border-radius: 0;
	background: none;
}

textarea {
	appearance: none;
	resize: vertical;
}

iframe {
	border: none;
}
