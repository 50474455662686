@import 'style';

.autosuggestwrapper {
	.react-autosuggest__container {
		position: relative;
		width: 100%;
	}

	.react-autosuggest__input {
		width: 100%;
		padding: 17 * $px 59 * $px 17 * $px 20 * $px;
		border-radius: 4 * $px;
		background: white;
		border: 1px solid rgba($brown-grey, 0.5);
		transition: border 0.3s ease-out;
	}

	.react-autosuggest__input--focused {
		outline: none;
		border-color: $brown-grey;
	}

	.react-autosuggest__input--open {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	.react-autosuggest__suggestions-container {
		display: none;
	}

	.react-autosuggest__suggestions-container--open {
		z-index: 999;
		display: block;
		position: absolute;
		top: 75 * $px;
		width: 100%;
		border: 1px solid $brown-grey;
		background-color: #fff;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	.react-autosuggest__suggestions-list {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	.react-autosuggest__suggestion {
		cursor: pointer;
		padding: 10 * $px 20 * $px;
	}

	.react-autosuggest__suggestion--highlighted {
		background-color: #ddd;
	}
}
